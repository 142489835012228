import React, { Component } from "react"
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Projects from "../components/projects"
import About from "../components/about"
import Contact from "../components/contact"

interface AppProps {
  //code related to your props goes here
}

interface AppState {
  pages: any
}

class Cara extends Component<AppState, AppProps> {

  render() {
    return (
      <Layout>
        <Parallax pages={6}>
          <Hero offset={0} />
          <Projects offset={1.4} />
          <About offset={4} />
          <Contact offset={5} />
        </Parallax>
      </Layout>
    )
  }
}

export default Cara
