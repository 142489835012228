/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import ProjectCard from "./project-card"
import SVG from "./svg"
import { UpDown, UpDownWide } from "../styles/animations"
import ProjectsMDX from "../sections/projects.mdx"

const reformatProjects = (projects) => (
  projects.map((curProject) => {
    const {
      title,
      content,
      acf
    } = curProject.node

    const {
      project_thumb: img,
      url
    } = acf

    return {
      title,
      content,
      img: img.url,
      url
    }
  })
)

const Projects = ({ offset }: { offset: number }) => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressWpProject {
        edges {
          node {
            id
            title
            content
            acf {
              url
              project_thumb {
                url
              }
            }
            date
          }
        }
      }
    }
  `)
  const { edges } = data.allWordpressWpProject
  const projects = reformatProjects(edges)

  return (
    <div>
      <Divider
        bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
        sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}
        speed={-0.2}
        offset={1.1}
        factor={2}
      />
      <Content speed={0.4} offset={offset + 0.2} factor={2}>
        <Inner>
          <div
            sx={{
              display: `grid`,
              gridGap: [4, 4, 4, 5],
              gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
              h2: { gridColumn: `-1/1` }
            }}
          >
            <ProjectsMDX />
            {projects.map((curProject, index) => (
              <ProjectCard
                index={ index }
                title={ curProject.title }
                link={curProject.url}
                bg={curProject.img}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: curProject.content,
                  }}
                ></div>
              </ProjectCard>
            ))}
          </div>
        </Inner>
      </Content>
      <Divider speed={0.1} offset={offset} factor={2}>
        <UpDown>
          <SVG icon="box" width={6} color="icon_brightest" left="85%" top="75%" />
          <SVG icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
          <SVG icon="triangle" width={8} stroke color="icon_orange" left="25%" top="5%" />
          <SVG icon="circle" hiddenMobile width={24} color="icon_brightest" left="17%" top="60%" />
        </UpDown>
        <UpDownWide>
          <SVG icon="arrowUp" hiddenMobile width={16} color="icon_green" left="20%" top="90%" />
          <SVG icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="30%" />
          <SVG icon="circle" width={16} color="icon_yellow" left="70%" top="90%" />
          <SVG icon="triangle" hiddenMobile width={16} stroke color="icon_teal" left="18%" top="75%" />
          <SVG icon="circle" width={6} color="icon_brightest" left="75%" top="10%" />
          <SVG icon="upDown" hiddenMobile width={8} color="icon_green" left="45%" top="10%" />
        </UpDownWide>
        <SVG icon="circle" hiddenMobile width={6} color="icon_brightest" left="4%" top="20%" />
        <SVG icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
        <SVG icon="box" width={6} color="icon_orange" left="10%" top="10%" />
        <SVG icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
        <SVG icon="hexa" width={16} stroke color="icon_red" left="75%" top="30%" />
        <SVG icon="hexa" width={8} stroke color="icon_yellow" left="80%" top="70%" />
      </Divider>
    </div>
  )
}

export default Projects
